import Login from './components/Login'
import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from './components/Dashboard';
import { TemplateConverter } from './pages';
import { AppShell } from './components';

import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { OrderProvider } from './contexts/order.context';

function App() {
    const { isAuthenticated } = useAuth0();
  return (
    <OrderProvider>
      <div className="app-container">
          <Login />
          {isAuthenticated && <Routes>
              {/* <Route path="upload" element={<Upload />} />
              <Route path="loader" element={<Loader />} /> */}
              <Route path="" element={<AppShell />}>
                  <Route path="" element={<Dashboard /> } />
                  <Route path="template-converter" element={<TemplateConverter />} />
              </Route>
          </Routes>}
      </div>
    </OrderProvider>
  );
}

export default App;
