import './AppShell.css';
import '../../css/shared.css';
import Sidebar from '../Sidebar';
import { Outlet } from 'react-router-dom';

export function AppShell() {
    return (
        <main className="app-shell flex-box">
            <aside className="app-sidebar">
                <Sidebar />
            </aside>
            <main className="app-route-outlet">
                <Outlet />
            </main>
        </main>
    )
}