import './PanelFooter.css';
import { orderService } from '../../../../http/order';
import { Notification } from '../../../Notifications/Notification';

export function PanelFooter(props) {
  const isImageField = (attributeName) => {
    // though these are mandatory they are non-editable so ignore image fields
    const lowerCase = attributeName.toLowerCase();
    return lowerCase.includes('image');
  }

  return (
    <div className="panel-footer">
      <button className='skip-btn' onClick={async () => {
        const res = await orderService.skipSKU(props.skuId);
        if(res && res['data'] && res['data']['status']==='QC-SKIPPED'){
          Notification('success', 'Unit Skipped', 'You can work on this unit after submitting remaining units in this batch');
        }else{
          Notification('error', 'Skip Failed', 'Unable to skip unit. Please reach out to us at support@textmercato.com for more assistance');
        }
        props.loadSku();
      }}>Skip</button>
      <button
        className='submit-btn'
        onClick={async () => {
          props.attemptSubmit();
          for (let op of props.outputFormat) {
            //console.log('op', op.attributeName);
            if (!op?.process_rule?.optional && !props.qcData[op.attributeName] && !isImageField(op.attributeName)) {
              //console.log('validation error:',op.attributeName, props, props.qcData[op.attributeName]);
              Notification('error', 'Validation Error', 'Please fill all mandatory fields (*)');
              return;
            }
          }
          const res = await orderService.submitSKU(props.skuId, props.qcData);
          if (res && res['data'] && res['data']['status']==='QC-COMPLETED'){
            //console.log(res);
            const pendingCount = props.totalCount - props.completedCount - 1;
            Notification('success', 'Unit submitted', `Unit Submitted, ${pendingCount} unit${pendingCount>1?'s':''} remaining in this batch`);
          }else{
            Notification('error', 'Submission Failed', 'Unable to submit unit. Please reach out to us at support@textmercato.com for more assistance');
          }
          props.loadSku();
        }}
      >Submit
      </button>
    </div>
  )
}