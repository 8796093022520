import { Header } from './Header/Header';
import { LeftPanel } from './LeftPanel/LeftPanel';
import { RightPanel } from './RightPanel/RightPanel';
import './EditorDashboard.css';
import { useEffect, useState } from 'react';
import { useOrder } from '../../hooks/order.hook';
import { orderService } from '../../http/order';
import { Spin } from 'antd';

export function EditorDashboard(props) {
  const { order } = useOrder();
  const [activeBatchIndex, setActiveBatchIndex] = useState(0);
  const [batchCounts, setBatchCounts] = useState([]);
  const [outputFormat, setOutputFormat] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [mappedData, setMappedData] = useState({});
  const [imageCollection, setImageCollection] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [skuId, setSkuId] = useState();
  const [isLoadingSku, setIsLoadingSku] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(()=>{
    const oldobj = JSON.parse(localStorage.getItem("userFlowObj"));
    const userFlowObj = {
      ...oldobj,
      flow_index: 2
    };
    localStorage.setItem("userFlowObj", JSON.stringify(userFlowObj));
    
  }, [])

  useEffect(() => {
    setIsLoadingSku(true);
    //console.log('id: ', order?._id);
    orderService.getBatchCounts(order?._id).then(async ({ data }) => {
      setBatchCounts(data);
      const { data: sku } = await orderService.fetchSKU(data[activeBatchIndex]?.batch_id);
      if (sku.templateData == null) {
        if (activeBatchIndex >= batchCounts.length) {
          props.moveToNextStep();
          return;
        } else {
          setActiveBatchIndex(i => i + 1);
          loadSku();
        }
      }
      //console.log('CURRENT SKU', sku)
      setSkuId(sku.templateData._id);
      setOriginalData(sku?.templateData?.data);
      setMappedData(sku?.templateData?.mapped_data);
      setOutputFormat(sku.outputFormat);
      setTotalCount(sku.total);
      setCompletedCount(sku.completed);
      setImageCollection(filterImagesFromJSON(sku?.templateData?.data));
      setIsLoadingSku(false);
    })
  }, [order, refreshKey]);

  const loadSku = () => {
    setRefreshKey((i) => i + 1);
  }

  const filterImagesFromJSON = (json) => {
    if (!json) return;
    const imageUrls = [];
    Object.keys(json).forEach((key) => {
      //console.log(json, key, json[key])
      if ((json[key]).toString()?.startsWith('https://')) {
        imageUrls.push({ thumbnail: json[key], original: json[key] });
      }
    });
    return imageUrls;
  }

  const items = {};
  return (
    <Spin tip="Loading..." spinning={isLoadingSku}>
      <div className='editorDashboard'>
        <Header currentBatch={activeBatchIndex} batchCounts={batchCounts} orderId={order?._id} />
        <div className="panels">
          <LeftPanel images={imageCollection}/>
          <RightPanel
            skuId={skuId} items={{ mappedData, inputData: outputFormat }} completedCount={completedCount}
            totalCount={totalCount} loadSku={loadSku}/>
        </div>
      </div>
    </Spin>
  )
}

