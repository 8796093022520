import './Header.css';
import { useOrder } from '../../../hooks/order.hook';
import { useEffect, useState } from 'react';
import { orderService } from '../../../http/order';
import { ExitModal } from '../../ExitModal/ExitModal';

export function Header(props) {
  const { batchCounts, currentBatch } = props;
  //console.log('editor header:', props);
  return(
    <div className="header">
      <div className='templates'>
        {batchCounts.map((b, i) => (
          <div className={`template ${i === 0 && 'template-first'} ${i!== 0 && i === (batchCounts.length-1) && 'template-last'} ${i!==0 && (i !== batchCounts.length - 1) &&  'template-mid'}`}>
          <div className='template-txt'>
            <p>Output Template {i+1}</p>
            <p>{i === currentBatch ? 'In progress' : i < currentBatch ? 'Completed' : 'Pending'}</p>
          </div>
          <div className='total'><p>{b.total} Units</p></div>
        </div>
        ))}
      </div>
      <div>
        <ExitModal orderId={props?.orderId}/>
      </div>
    </div>
  )
}