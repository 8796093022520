import { httpClient } from ".";

async function pingKriya() {
    return httpClient.get('ping-kriya');
}

async function createOrder(formData, updateProgress) {
    return httpClient.postMultiPartFormData('api/order/upload', formData, updateProgress)
}

async function completeOrder(data) {
    return httpClient.put('api/orders/complete', data)
}

async function startMapping(orderId) {
    return httpClient.put('api/orders/start-mapping', { orderId });
}

async function rejectOrder(orderId) {
    console.log('rejecting the order', orderId);
 return httpClient.put('api/orders/reject', { orderId });
}


async function getStatus(orderId) {
    return httpClient.get(`api/order/status?id=${orderId}`);
}

async function getOutputFiles(orderId) {
    return httpClient.get(`api/order/outputs?id=${orderId}`);
}

async function getBatchCounts(orderId) {
    return httpClient.get(`api/orders/orderBatchCount?orderId=${orderId}`);
}

async function fetchSKU(batchId) {
    return httpClient.get(`api/orders/fetchQcsku?batchId=${batchId}`);
}

async function submitSKU(skuId, newJson) {
    return httpClient.put(`api/orders/submitQcSku?skuId=${skuId}`, { qcData: newJson });
}

async function skipSKU(skuId) {
    return httpClient.put(`api/orders/skipQcSku?skuId=${skuId}`, {});
}

export const orderService = {
    pingKriya, createOrder,startMapping, getStatus, getOutputFiles, getBatchCounts, rejectOrder, fetchSKU, submitSKU, skipSKU, completeOrder
}
