import './FileInfo.css';
import '../../css/shared.css';
import PDFFileIcon from '../../static/images/pdf_file.svg';
import CloseIcon from '../../static/images/close_icon.svg';
import TickIcon from '../../static/images/success_icon.svg';

export function FileInfo(props) {
    const { file, cancelUploadedFile, order, isCreatingOrder, uploadProgress } = props;
    file.sizeInMB = (file.size / 1048576).toPrecision(2);
    return (
        <section className="file-info flex-box flex-start flex-ai-start">
            <img src={PDFFileIcon} height="100%" width="20%" />
            <div className="name-upload-details flex-box flex-column-dir flex-ai-start">
                <div className="file-name-percentage flex-box flex-sb">
                    <span className="file-name">{file.name}</span>
                    { order != null && isCreatingOrder == false && <img src={TickIcon} />}
                </div>
                {!isCreatingOrder && <div className="file-upload-size flex-box flex-start">
                    <span>{file.sizeInMB} MB</span>
                </div>}
                {isCreatingOrder && <div className="file-upload-size flex-box flex-start">
                    <div style={{backgroundColor: '#C4C4C4', borderRadius: '5px', height: '50%', width: uploadProgress+'%'}} value={uploadProgress}></div>
                </div>}
            </div>
            <div className="cancel-upload flex-box flex-column-dir flex-ai-start">
                {order != null && isCreatingOrder == false && <img onClick={cancelUploadedFile} src={CloseIcon} />}
            </div>
        </section>
    )
}