import './PanelHeader.css';
import ProgressIcon from '../../../../static/images/progress-icon.png';
import { Progress } from 'antd';

export function PanelHeader(props) {
  return (
    <div className="panel-header">
      <div className='template-details'>
        <p>Unit {props.completedCount + 1}</p>
        <p>Output Template {props.completedCount + 1}</p>
      </div>
      <div className='template-progress'>
        <p style={{color: '#fff'}}><img className='progress-icon' src={ProgressIcon} alt="Icon"/> {props.completedCount} of {props.totalCount} Units Completed</p>
        <Progress percent={props.completedCount * 100/props.totalCount}  strokeColor={'lime'} showInfo={false} />
      </div>
    </div>
  )
}