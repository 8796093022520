import './RequestCreation.css';
import '../../css/shared.css';
import FileUploadIcon from '../../static/images/file_upload.svg';
import ExitIcon from '../../static/images/exit_icon.svg';
import { FileInfo } from '../FileInfo/FileInfo';
import { useEffect, useState } from 'react';
import { orderService } from '../../http/order';
import WarningInfoIcon from '../../static/images/warning_info.svg';
import {Modal} from 'antd';
import  {useNavigate} from "react-router-dom";
import { useOrder } from '../../hooks/order.hook';
import { ExitModal } from '../ExitModal/ExitModal';

export function RequestCreation(props) {
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isErrorOccured, setIsErrorOccured] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { setOrder, setBatches, setIsLoading,order } = useOrder();
    const { moveToNextStep } = props;
    const [ isCreatingOrder, setIsCreatingOrder ] = useState(false);
    const [ uploadProgress, setUploadProgress ] = useState(0);

    const navigate = useNavigate();

//   const showModal = () => {
//     setIsModalVisible(true);
//   };

//   const handleOk = () => {
//     setIsModalVisible(false);
//     navigate("/");
//   };

//   const handleCancel = () => {

//     setIsModalVisible(false);
//   };
    
    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsFileSelected(true);
        setIsCreatingOrder(true);
    }

    const cancelUploadedFile = async () => {
        const response = await orderService.rejectOrder(order._id);
        if(response.data.modifiedCount === 1) {
            setIsFileSelected(false);
            setSelectedFile(null);
            setOrder(null);
        }
    }

    useEffect(() => {
        if(order) {
            console.log('order details');
            console.log(order);
            if(order.status === 'PENDING') {
                const fileUrl = decodeURI(order.file_url);
                const fileParts = fileUrl.split('/');
                console.log(fileParts);
                let filename = fileParts[fileParts.length-1];
                filename = filename.substring(filename.indexOf('_')+1, filename.length)
                setSelectedFile({
                    name: filename,
                    size: order.file_size
                })
                setIsFileSelected(true);
            }
        }
    }, [order])

    useEffect(() => {
        if(selectedFile && isCreatingOrder === true) {
            try {
                findIsValidFileFormat(selectedFile.name);
                console.log('creating order')
                createOrder();
            }
            catch(err) {
                console.error(err);
            }
        }
    }, [isCreatingOrder, selectedFile])

    const allowedExtensions = ['xlsx', 'xls'];

    const updateProgress = (progress) => {
        const { loaded, total } = progress;
        setUploadProgress((loaded/total)*100)
    }

    const allowDrop = (ev) => {
        ev.preventDefault();
    }

    const drop = async (ev) => {
        ev.preventDefault();
        if(Object.keys(ev.dataTransfer.files).length > 1) {
            setIsErrorOccured(true);
            setErrorMessage('Sorry! Only one template can be uploaded at a time. Please retry');
            if(order) {
                await cancelUploadedFile();
                setIsFileSelected(false);
                setSelectedFile(null);
            }
        }
        else {
            if(selectedFile != null) {
                setIsFileSelected(false);
                setSelectedFile(null);
                setIsErrorOccured(true);
                await cancelUploadedFile();
                setErrorMessage('Sorry! Only one template can be uploaded at a time. Please retry');
            }
            else {
                setSelectedFile(ev.dataTransfer.files[0]);
                setIsFileSelected(true);
                setIsCreatingOrder(true);
            }
        }
    }

    const findIsValidFileFormat = (fileName) => {
        const fileparts = fileName.split('.');
        const extension = fileparts[fileparts.length-1];

        if(!allowedExtensions.includes(extension)) {
            setIsFileSelected(false);
            setSelectedFile(null);
            setIsErrorOccured(true);
            setIsCreatingOrder(false);
            setErrorMessage('Sorry! This file format is not supported. Please try using an Excel file');
            throw 'file format not supported';
        }
        else {
            setIsErrorOccured(false);
            setErrorMessage('');
        }
    }

    const rejectOrder = async (orderId) =>{
        console.log('rejecting the order', orderId);
        const response = await orderService.rejectOrder(orderId);
        setIsModalVisible(false);
        navigate('/');
        return response;
    }

    const startMappingProcess = async () => {
        console.log('starting mapping process');
        const response = await orderService.startMapping(order._id);
        if(response.data.success === true) {
            setOrder(response.data.message.updatedOrder);
            console.log('mapping process started');
            moveToNextStep();
        }
    }

    const createOrder = async () => {
        const formData = new FormData();
        formData.set('file', selectedFile);
        const response = await orderService.createOrder(formData, updateProgress);
        setIsCreatingOrder(false);
        if(response.data.success == true) {
            setIsErrorOccured(false);
            setErrorMessage('');
            setOrder(response.data.data.order);
            setBatches(response.data.data.batches);
            setIsLoading(false);
        }
        else {
            setIsErrorOccured(true);
            setErrorMessage(response.data.error);
            setIsFileSelected(false);
            setSelectedFile(null);
        }
    }

    return (
        <>
        <section className="request-creation flex-box flex-column-dir">
            <ExitModal orderId={order?._id}/>
            {/* <div className="create-order-header flex-box flex-end">
                <button onClick={showModal} style={{width: '10%'}} className="cus-button danger flex-box">
                    <img src={ExitIcon} />
                    <span>Exit</span>
                </button>
                <Modal title="Save" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText='Save' cancelText='Discard'>
                    
                    <div style={{color: 'black'}}>
                        <p>Are You Sure You Want To Exit ?</p>
                        <p>*Please Note this action cannot be undone.</p>
                    </div>
                </Modal>

               
            </div>
             */}
            <div className="create-request flex-box">
                <section className="request-creation__file-upload flex-box flex-column-dir" onDrop={drop} onDragOver={allowDrop} onDragEnter={allowDrop}>
                    { (!isFileSelected || isErrorOccured) && <label  className="request-creation__info flex-box flex-column-dir">
                        <img src={FileUploadIcon} />
                        <input accept={allowedExtensions.map(ext => '.'+ext).join(',')} onChange={onFileChange} type="file" id="orderFile" style={{display:'none'}}></input>
                        <p htmlFor="orderFile" className="request-creation__text">Click to browse or <br /> drag and drop your files</p>
                    </label>}
                    {isErrorOccured && <p> <img src={WarningInfoIcon} /><span className='file-upload-err-msg'>{errorMessage}</span></p>}
                    { (isFileSelected && !isErrorOccured) && <div className="request-file-info">
                        <FileInfo order={order} isCreatingOrder={isCreatingOrder} uploadProgress={uploadProgress} cancelUploadedFile={cancelUploadedFile} file={selectedFile} />
                    </div> }
                </section>
            </div>
            <div className="create-order-footer flex-box flex-end">
                <button onClick={() => startMappingProcess()} disabled={!isFileSelected} className="cus-button primary flex-box proceed-btn" style={{width: '13%'}}
                >
                    <span>Confirm</span>
                </button>
                {/* <button style={{width: '13%', color: '#848484'}} className="cus-button transparent flex-box">
                    <span>Back</span>
                </button> */}
            </div>
        </section>
        </>
    )
}